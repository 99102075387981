import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"

import Image from "../image"

import ScrollArrow from "../../assets/svg/scroll-arrow.svg"
import ArrowLeft from "../../assets/svg/arrow-left.svg"
import ArrowRight from "../../assets/svg/arrow-right.svg"

const Pillars = ({ title, text, pillars }) => {
  const
    [value, setValue] = useState(0),
    pillarOneRef = useRef(),
    pillarTwoRef = useRef(),
    pillarThreeRef = useRef(),
    pillarFourRef = useRef(),
    pillarFiveRef = useRef(),
    pillarSixRef = useRef(),
    pillarSevenRef = useRef(),
    isOneVisible = useOnScreen(pillarOneRef),
    isTwoVisible = useOnScreen(pillarTwoRef),
    isThreeVisible = useOnScreen(pillarThreeRef),
    isFourVisible = useOnScreen(pillarFourRef),
    isFiveVisible = useOnScreen(pillarFiveRef),
    isSixVisible = useOnScreen(pillarSixRef),
    isSevenVisible = useOnScreen(pillarSevenRef);

  function useOnScreen(ref) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      );
    }, []);

    useEffect(() => {
      observerRef.current.observe(ref.current);

      return () => {
        observerRef.current.disconnect();
      };
    }, [ref]);

    return isOnScreen;
  }

  useEffect(() => {
    if(isOneVisible) {
      setValue(0)
    }
    if(isTwoVisible) {
      setValue(1)
    }
    if(isThreeVisible) {
      setValue(2)
    }
    if(isFourVisible) {
      setValue(3)
    }
    if(isFiveVisible) {
      setValue(4)
    }
    if(isSixVisible) {
      setValue(5)
    }
    if(isSevenVisible) {
      setValue(6)
    }
  }, [isOneVisible, isTwoVisible, isThreeVisible, isFourVisible, isFiveVisible, isSixVisible, isSevenVisible])

  const [mobileValue, setMobileValue] = useState(0)

  const moveBackMobile = () => {
    setMobileValue(mobileValue - 1)
  }

  const moveForwardMobile = () => {
    setMobileValue(mobileValue + 1)
  }

  let
    MobileSliderClasses = classNames({
      'slide': true,
      'slide-two': mobileValue === 1,
      'slide-three': mobileValue === 2,
      'slide-four': mobileValue === 3,
      'slide-five': mobileValue === 4,
      'slide-six': mobileValue === 5,
      'slide-seven': mobileValue === 6
    });

  return (
    <section className="culture--pillars">
      <div className="grid-16 header">
        <h2 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{title}</h2>
        <p data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M24-light">{text}</p>
      </div>

      <div className="pillars-container">
        <section  className="pillar-container">
          <div className="half-block texts">
            <div >
              <p className="M16-light index">1/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarOneRef}>{pillars[0]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[0]._rawDescription}</p>
              <ScrollArrow className="scroll-arrow"/>
            </div>
            <div >
              <p className="M16-light index">2/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarTwoRef}>{pillars[1]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[1]._rawDescription}</p>
            </div>
            <div>
              <p className="M16-light index">3/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarThreeRef}>{pillars[2]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[2]._rawDescription}</p>
            </div>
            <div >
              <p className="M16-light index">4/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarFourRef}>{pillars[3]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[3]._rawDescription}</p>
            </div>
            <div >
              <p className="M16-light index">5/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarFiveRef}>{pillars[4]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[4]._rawDescription}</p>
            </div>
            <div>
              <p className="M16-light index">6/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarSixRef}>{pillars[5]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[5]._rawDescription}</p>
            </div>
            <div>
              <p className="M16-light index">7/7</p>
              <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M40-light" ref={pillarSevenRef}>{pillars[6]._rawTitle}</h3>
              <p data-sal='fade' data-sal-duration='450' data-sal-easing='ease' className="M24-light description">{pillars[6]._rawDescription}</p>
            </div>
          </div>
          <div className="half-block image">
            <Image image={pillars[value].image.asset} alt={`${pillars[value].title} | Latente Studio`}/>
          </div>
        </section>
      </div>

      <div className="mobile-slider-container">
        <div className="controls-container">
          <button type="button" className="arrow-slide mobile" onClick={moveBackMobile} disabled={mobileValue === 0}><ArrowLeft /></button>
          <span>{mobileValue + 1}/7</span>
          <button type="button" className="arrow-slide right mobile" onClick={moveForwardMobile} disabled={mobileValue === 6}><ArrowRight/></button>
        </div>

        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="slider-pillars">
          <div className={MobileSliderClasses}>
            {pillars?.map(({ _key, image, _rawTitle, _rawDescription }) => (
              <div className="p-container" key={_key} data-sal="fade" data-sal-duration="500">
                <Image image={image.asset} alt={`${pillars[value].title} | Latente Studio`}/>
                <div className="p-text">
                  <h4>{_rawTitle}</h4>
                  <p>{_rawDescription}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

  )
}

export default Pillars
