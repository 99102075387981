import React from "react"
import { Link } from "gatsby"

import BoldParagraph from "../boldParagraph"

import Arrow from "../../assets/svg/cta_arrow.svg"

const Journey = ({ title, journey, ctaTitle, route }) => (
  <section className="grid-16 culture--journey">
    <h2 data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="M60-regular">{title}</h2>
    <BoldParagraph content={journey} containerClassName="journey" elemClassName="M40-light"/>
    <Link className="cta" to={route}><span>{ctaTitle}</span><Arrow className="arrow"/></Link>
  </section>
)

export default Journey
