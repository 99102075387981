import React from "react"

import Image from "../Project/Image"
import BlockContent from "../blockContent"

const Member = ({ member: { name, job, bio, images} }) => {
  return (
    <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="member-container">
      <h5 className="M16-regular">{name}</h5>
      <p className="job">{job}</p>

      <div className="images-container">
        {images?.map((image, i) => {
          return (
            <Image key={i} image={image} className={`c-${i}`} alt={`${name} | Latente Studio`}/>
          )
        })}
      </div>

      <BlockContent className="bio" blocks={bio || []}/>
    </div>
  )
}

export default Member
