import React, { useRef, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { localize } from "../lib/helpers"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Culture/hero"
import VideoNoControls from "../components/videoNoControls"
import Image from "../components/Project/Image"
import Journey from "../components/Culture/journey"
import Team from "../components/Culture/team"

import Untangle from "../assets/svg/untangle.svg"
import HalfBlock from "../components/halfBlock"
import Pillars from "../components/Culture/pillars"
import Collaborators from "../components/Culture/collaborators"

const CulturePage = ({ data, pageContext, location }) => {
  const
    { i18n } = useTranslation('common'),
    { _rawTitle, _rawBannerTitle, _rawYear, _rawYear2, _rawYear3, _rawSubtitle, _rawBlockVideo,
    _rawImage, _rawJourneyTitle, journey, _rawJourneyCta, _rawBlockTitle, blockText2, _rawTeamTitle,
    _rawMembers, _rawTeamCta, _rawPillarsTitle, _rawPillarsText, pillars, _rawCollaborators,
    collaboratorsText, collaboratorsList, _rawCollaboratorsCta  } = localize(data.sanityCulture, [pageContext.language]);

  const
    untangleRef = useRef(),
    isUntangleVisible = useOnScreen(untangleRef);

  function useOnScreen(ref) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      );
    }, []);

    useEffect(() => {
      observerRef.current.observe(ref.current);

      return () => {
        observerRef.current.disconnect();
      };
    }, [ref]);

    return isOnScreen;
  }


  return(
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location}/>
      <div id="culture">
        <Hero title={_rawBannerTitle} year={_rawYear} year2={_rawYear2} year3={_rawYear3} subtitle={_rawSubtitle}/>

        <section className="culture--video-image">
          <VideoNoControls video={_rawBlockVideo.vimeoVideo.oEmbedData.html}/>
          <div data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="half-block">
            <Image image={_rawImage}/>
          </div>
        </section>

        <Journey title={_rawJourneyTitle} journey={journey} ctaTitle={_rawJourneyCta.title} route={`/${i18n.language}/${_rawJourneyCta.route}`}/>

        <section className="culture--untangle--team">
          <div className="untangle-container center" ref={untangleRef}>
            <Untangle className={isUntangleVisible ? "untangle animate" : "untangle"}/>
          </div>
          <HalfBlock title={_rawBlockTitle} text={blockText2}/>
        </section>

        <Team title={_rawTeamTitle} members={_rawMembers} cta={_rawTeamCta}/>

        <Pillars title={_rawPillarsTitle} text={_rawPillarsText} pillars={pillars}/>

        <Collaborators title={_rawCollaborators} text={collaboratorsText} list={collaboratorsList} cta={_rawCollaboratorsCta}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CulturePage($language: String)  {
    sanityCulture {
      _rawTitle
      _rawBannerTitle
      _rawYear
      _rawYear2
      _rawYear3
      _rawSubtitle
      _rawBlockVideo
      _rawImage
      _rawJourneyTitle
      journey {
        translate(language: $language)
        isBold
      }
      _rawJourneyCta
      _rawBlockTitle
      blockText2 {
        translate(language: $language)
        isBold
      }
      _rawTeamTitle
      _rawMembers(resolveReferences: {maxDepth: 5})
      _rawTeamCta
      _rawPillarsTitle
      _rawPillarsText
      pillars {
        _rawTitle
        _rawDescription
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 1080)
          }
        }
        _key
      }
      _rawCollaborators
      collaboratorsText {
        translate(language: $language)
        isBold
      }
      collaboratorsList
      _rawCollaboratorsCta
    }
  }
`

export default CulturePage
