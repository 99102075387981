import React, { useRef, useEffect, useState } from "react"
import Typewriter from "typewriter-effect"

import BoldParagraph from "../boldParagraph"
import Cta from "../cta"

const Collaborators = ({ title, text, list, cta }) => {
  const typeRef = useRef(null)
  const isVisible = useOnScreen(typeRef)
  const [hasStarted, setHasStarted] = useState(false)

  function useOnScreen(ref) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      );
    }, []);

    useEffect(() => {
      observerRef.current.observe(ref.current);

      return () => {
        observerRef.current.disconnect();
      };
    }, [ref]);

    return isOnScreen;
  }

  useEffect(() => {
    if(isVisible) {
      setHasStarted(true)
    }
  }, [isVisible])

  return (
    <section className="grid-16 culture--collaborators">
      <h2 data-sal='fade' data-sal-duration='750' data-sal-easing='ease'  className="M60-regular">{title}</h2>
      <BoldParagraph content={text} elemClassName="TT30-elight" containerClassName="collaborators-text"/>

      <div data-sal='fade' data-sal-duration='500' data-sal-easing='ease'  className="list" ref={typeRef}>
        <Typewriter
          options={{
            strings: list.join(""),
            autoStart: hasStarted,
            delay: 70,
          }}
          onInit={(typewriter) => {
            typewriter
              .start()
          }}
        />
      </div>

      <Cta cta={cta}/>
    </section>
  )
}

export default Collaborators
