import React, { useState } from "react"
import classNames from "classnames"
import Cta from "../cta"
import Member from "./member"

import ArrowLeft from "../../assets/svg/arrow-left.svg"
import ArrowRight from "../../assets/svg/arrow-right.svg"

const Team = ({ title, members, cta, }) => {
  const [mobileValue, setMobileValue] = useState(0)

  let
    MobileSliderClasses = classNames({
      'slide': true,
      'slide-two': mobileValue === 1,
      'slide-three': mobileValue === 2,
      'slide-four': mobileValue === 3,
      'slide-five': mobileValue === 4,
      'slide-six': mobileValue === 5,
      'slide-seven': mobileValue === 6,
      'slide-eight': mobileValue === 7,
      'slide-nine': mobileValue === 8,
      'slide-ten': mobileValue === 9,
    });

  const moveBackMobile = () => {
    setMobileValue(mobileValue - 1)

    if (mobileValue === 0) {
      setMobileValue(3)
    }
  }

  const moveForwardMobile = () => {
    setMobileValue(mobileValue + 1)

    if (mobileValue === members.length) {
      setMobileValue(0)
    }
  }

  return (
    <section className="grid-16 culture--team">
      <h2 data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M60-regular">{title}</h2>

      <div className="members">
        {members?.map(member => {
          return (
            <Member key={member._id} member={member}/>
          )
        })}
      </div>

      <div className="mobile-slider-container">
        <div className="controls-container">
          <button type="button" className="arrow-slide mobile" onClick={moveBackMobile} disabled={mobileValue === 0}><ArrowLeft /></button>
          <span>{mobileValue + 1}/{members.length}</span>
          <button type="button" className="arrow-slide right mobile" onClick={moveForwardMobile} disabled={mobileValue === (members.length - 1)}><ArrowRight/></button>
        </div>

        <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="slider-members members m">
          <div className={MobileSliderClasses}>
            {members?.map(member => {
              return (
                <Member key={member._id} member={member}/>
              )
            })}
          </div>
        </div>


      </div>

      <Cta cta={cta}/>
    </section>
  )
}

export default Team
