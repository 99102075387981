import React from "react"

const Hero = ({ title, year, year2, year3, subtitle }) => {
  return (
    <section className="grid-16 culture--hero">
      <p data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M16-regular title">{title}</p>
      <p data-sal='fade' data-sal-duration='1000' data-sal-easing='ease' className="TT120-hairline year">{year}</p>
      <p data-sal='fade' data-sal-duration='1200' data-sal-easing='ease' data-sal-delay="200" className="TT120-hairline year">{year2}</p>
      <p data-sal='fade' data-sal-duration='1400' data-sal-easing='ease' data-sal-delay="250" className="TT120-hairline year">{year3}</p>
      <p data-sal='fade' data-sal-duration='500' data-sal-easing='ease' className="M16-light subtitle">{subtitle}</p>
    </section>
  )
}

export default Hero
